import { AgGridReact } from "ag-grid-react";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import GridComponent from "../../../../components/grid/gridComponent";
import PageTitle from "../../../shared/layout/pageTitle/pageTitle";
import GridTotalCountHeader from "../../../../components/grid/gridTotalCountHeader";
import { useAppDispatch } from "../../../../store/hooks";
import { getOrderHistory } from "../../../../store/api/apiSlice";
import getFiles from "../../../shared/csvDownload/csvDownload";
import { useLocation, useNavigate } from "react-router-dom";
import {
  COLUMN_CONFIG_REPORT_NAMES,
  formatDatePipe,
  formateIds,
  gridAlignment,
  visibleColumnNames,
} from "../../../../interface/comman.constant";
import { getTableFields } from "../../../../helpers/getTableFields";
import { useSelector } from "react-redux";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { useUserSettings } from "../../../../helpers/hooks/useUserSettings";
import { showConfigColumns } from "../../../../helpers/getSortedVisibleCols";
import { cloneDeep } from "lodash";

function OrderHistory() {
  const [showProductTour, setShowProductTour] = useState(false);
  const [joyRideKey, setJoyRideKey] = useState(0);
  const journeySteps = [
    {
      target: ".one",
      placement: "center" as const,
      content:
        "This page offers the list of the Order History. And allow you to dive deeper into the data for each order.",
    },
    {
      target: ".two",
      content:
        "Use the search bar to find specific text within the report. The Clear All Filters icon will clear any column-specific filters applied to the report. Click the Export icon to export the report to excel. Use the Customize Columns icon to show/hide specific columns relevant to your needs.",
    },
  ];
  const gridRef = useRef<AgGridReact>(null);
  const myRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { state: locationState } = useLocation();
  const [gridApi, setGridApi] = useState<any>(null);
  const userAccounts =
    useSelector((state: any) => state?.accounts?.chosenIds[0]) || [];
  const refreshPage =
    useSelector((state: any) => state?.accounts?.refreshPage) || false;
  const navigate = useNavigate();
  const [gridData, setGridData] = useState({
    sortOrder: "",
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [100, 200, 300],
    pageSize: 100,
    page: 1,
    globalSearch: "",
    totalCreditedERV: 0,
    totalOpenERV: 0,
    isForExcel: false,
    sortField: "",
    csvName: "OrderHistory.csv",
    URL: "Reports/GetOrderHistory",
    filterOptions: [],
  });

  const columnItems = [
    {
      headerName: "Order#",
      headerTooltip: "Order#",
      tooltipShowDelay: 0,
      field: "orderNumber",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
      cellStyle: { cursor: "pointer" },
      pinned: "left",
      cellRenderer: function (params: any) {
        return (
          <div
            className={`text-link`}
            onClick={(e) => {
              if (params.data.returnProgram === "Partnered Wholesaler") {
                localStorage.setItem(
                  "PartneredRow",
                  JSON.stringify(params.data)
                );
                navigate("/reports/partner-summary/" + params.data.orderNumber);
              } else if (
                params.data.returnProgram === "Non-Partnered Wholesaler"
              ) {
                localStorage.setItem(
                  "PartneredRow",
                  JSON.stringify(params.data)
                );
                navigate("/reports/partner-summary/" + params.data.orderNumber);
              } else if (params.data.returnProgram === "RCP") {
                localStorage.setItem("RCPRow", JSON.stringify(params.data));
                navigate("/reports/rapid-credit/" + params.data.orderNumber);
              } else if (params.data.returnProgram === "Drug Liquidation") {
                localStorage.setItem(
                  "DrugLiquidationRow",
                  JSON.stringify(params.data)
                );
                navigate(
                  "/reports/drug-liquidation/" + params.data.orderNumber
                );
              }
            }}
          >
            {params.data.orderNumber}
          </div>
        );
      },
    },
    {
      headerName: "Pickup Date",
      field: "pickupDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
      },
      hide: true,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Process Date",
      headerTooltip: "Process Date",
      tooltipShowDelay: 0,
      field: "processDate",
      filter: "agDateColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ["reset"],
        debounceMs: 1000,
      },
      hide: false,
      valueFormatter: function (params: any) {
        return formatDatePipe(params.value);
      },
      comparator: customComparator,
    },
    {
      headerName: "Account Hierarchy",
      field: "accountHierarchy",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      comparator: customComparator,
    },
    {
      headerName: "Customer ID",
      headerTooltip: "Customer ID",
      tooltipShowDelay: 0,
      field: "customerId",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Customer Name",
      headerTooltip: "Customer Name",
      tooltipField: "customerName",
      tooltipShowDelay: 0,
      field: "customerName",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      comparator: customComparator,
    },
    {
      headerName: "Return Program",
      field: "returnProgram",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      comparator: customComparator,
    },
    {
      headerName: "Returned ERV",
      headerTooltip: "Returned ERV",
      tooltipShowDelay: 0,
      field: "returnedERV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Credited ERV",
      field: "creditedERV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Denied ERV",
      field: "deniedERV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Outstanding ERV",
      field: "outstandingERV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Pending ARV",
      headerTooltip: "Pending ARV",
      tooltipShowDelay: 0,
      field: "pendingARV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Disbursed ARV",
      headerTooltip: "Disbursed ARV",
      tooltipShowDelay: 0,
      field: "disbursedARV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: false,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Direct ERV",
      field: "directERV",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    {
      headerName: "Item Processed",
      field: "itemProcessed",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset"],
        debounceMs: 1000,
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
      hide: true,
      type: gridAlignment.RIGHT,
      comparator: customComparator,
    },
    // {
    //   headerName: "",
    //   field: "",
    //   suppressMenu: true,
    //   floatingFilterComponentParams: { suppressFilterButton: true },
    //   pinned: "right",
    //   minWidth: 40,
    //   maxWidth: 90,
    //   flex: 1,
    //   cellRenderer: function (params: any) {
    //     return (
    //       <Link to={`/reports/rapid-credit/${params.data.orderNumber}`}>
    //         <Button variant="primary" size="sm" className="px-3">
    //           RCP
    //         </Button>
    //       </Link>
    //     );
    //   },
    // },
  ];

  const { id, dbColumnConfig } = useUserSettings(COLUMN_CONFIG_REPORT_NAMES?.OrderHistory);
  const [colDefs, setColDefs] = useState(() => showConfigColumns(columnItems, dbColumnConfig));

  function customComparator() {
    return 0; //means no comparing and no sorting client side
  }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    // setTimeout(() => {
    //   params.api.autoSizeAllColumns();
    // }, 100);
  };

  useEffect(() => {
    const tour = localStorage.getItem("product_tour");
    if (tour === "true") {
      setJoyRideKey((k) => k + 1);
      setShowProductTour(true);
    }
    if (gridData.globalSearch) {
      const getData = setTimeout(() => {
        orderHistory();
      }, 2000);

      return () => clearTimeout(getData);
    } else {
      orderHistory();
    }
  }, [
    gridData.page,
    gridData.pageSize,
    gridData.sortOrder,
    gridData.sortField,
    gridData.globalSearch,
    gridApi,
    refreshPage,
    locationState
  ]);

  useEffect(() => {
    if (gridData.isForExcel === true) {
      getFiles(gridData, visibleColumnNames(colDefs), formateIds(userAccounts));
      updateGridData("isForExcel", false);
    }
  }, [gridData.isForExcel]);

  useEffect(() => {
    console.log('dbColumnConfig', dbColumnConfig);
    if (Object.keys(dbColumnConfig).length > 0)
      setColDefs(showConfigColumns(columnItems, dbColumnConfig));
  }, [dbColumnConfig]);

  const updateGridData = (k: any, v: any) =>
    setGridData((prev) => ({ ...prev, [k]: v }));

  const popupSave = (event: any, columnValues: any) => {
    event.preventDefault();

    console.log('columnValues', columnValues);

    setColDefs(cloneDeep(columnValues));
    myRef?.current.handleClose();
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    if (data.action === "close" || data.action === "skip") {
      setShowProductTour(false);
      localStorage.removeItem("product_tour");
    }
  };
  // this function defination change after enterprise
  const orderHistory = async () => {
    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params: any) => {
            let data = {
              pageNumber:
                gridApi.paginationGetCurrentPage() === 0
                  ? 1
                  : gridApi.paginationGetCurrentPage() + 1,
              pageSize: gridData.pageSize,
              sortField: gridData.sortField,
              sortDirection: parseInt(gridData?.sortOrder || "1"),
              isForExcel: gridData.isForExcel,
              globalSearch: gridData.globalSearch,
              filterOptions: getTableFields(params.request.filterModel),
              CustomerIds: formateIds(userAccounts),
            };
            updateGridData(
              "filterOptions",
              getTableFields(params.request.filterModel)
            );
            updateGridData("userAccounts", userAccounts);
            dispatch(getOrderHistory(data)).then((result: any) => {
              if (result?.payload?.data?.orderHistories?.length === 0) {
                gridApi.showNoRowsOverlay();
                params.success({
                  rowData: [],
                  rowCount: 0,
                });
              } else {
                gridApi!.hideOverlay();
                params.success({
                  rowData: result?.payload?.data?.orderHistories,
                  rowCount: result?.payload?.data?.totalCount,
                });
              }
              updateGridData(
                "totalCreditedERV",
                result?.payload?.data?.totalCreditedERV
              );
              updateGridData(
                "totalOpenERV",
                result?.payload?.data?.totalOpenERV
              );
              updateGridData("totalRows", result?.payload?.data?.totalCount);
            });
          },
        };

        gridApi.setGridOption("serverSideDatasource", dataSource);
      }
    } catch (error) { }
  };

  const onSortChanged = ({
    api: { sortController },
  }: {
    api: { sortController: any };
  }): void => {
    const sortModel: any[] = sortController.getSortModel();

    if (sortModel.length > 0) {
      const { colId, sort } = sortModel[0];

      updateGridData("sortOrder", sort === "asc" ? "0" : "1");
      updateGridData("sortField", colId);
    } else {
      updateGridData("sortOrder", "");
      updateGridData("sortField", "");
    }
  };

  const globalSearch = (e: any) => {
    updateGridData("globalSearch", e.target.value);
  };

  const onExport = (e: any) => {
    updateGridData("isForExcel", true);
  };

  const onClearFilter = () => {
    gridApi!.setFilterModel(null);
    updateGridData("globalSearch", "");
  };
  const paginationChange = () => {
    if (gridApi) {
      updateGridData("pageSize", gridApi.paginationGetPageSize());
    }
  };
  return (
    <Container fluid>
      <Joyride
        key={`joyride-${joyRideKey}`}
        run={showProductTour}
        steps={journeySteps}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
      />
      <PageTitle
        name="Order History"
        buttonName="Back"
        routePage="/reports/view-all"
      ></PageTitle>
      <div className={`content_body ${showProductTour ? "one" : ""}`}>
        <GridTotalCountHeader
          isTwoColumn={true}
          title="Open ERV"
          count={gridData.totalOpenERV}
          title1="Total Credit"
          count1={gridData.totalCreditedERV}
        ></GridTotalCountHeader>
        <GridComponent
          dbUserReportId={id}
          dbReportName={COLUMN_CONFIG_REPORT_NAMES?.OrderHistory}
          dbColumnConfig={dbColumnConfig}
          defaultColumns={columnItems}
          showProductTour={showProductTour}
          ref={myRef}
          gridRef={gridRef}
          rowData={gridData.rows}
          colDefs={colDefs}
          onGridReady={onGridReady}
          popupSave={popupSave}
          paginationPageSize={gridData.pageSize}
          paginationPageSizeSelector={gridData.rowsPerPageOptions}
          sortChange={onSortChanged}
          globalSearch={globalSearch}
          onExport={onExport}
          gridData={gridData}
          setGridData={setGridData}
          onClearFilter={onClearFilter}
          paginationChange={paginationChange}
          autoSizeStrategy={{
            type: "fitCellContents",
            colIds: [
              "pickupDate",
              "Order#",
              "processDate",
              "directERV",
              "disbursedARV",
            ],
          }}
        />
      </div>
    </Container>
  );
}
export default OrderHistory;
